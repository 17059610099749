<template>
	<!-- 添加订单 -->
	<div class="addorder">
		<MyHeader :userName="userName"></MyHeader>
		<Nav></Nav>
		
		<div class="con">
			<div class="stepcon">
				<el-steps :active="$store.state.stepIndex" align-center>
				  <el-step @click.native="goOrderOne"  title="患者信息" description=""></el-step>
				  <el-step @click.native="goOrderTwo" title="治疗需求" description=""></el-step>
				  <el-step @click.native="goOrderThree" title="上传X光片" description=""></el-step>
				  <el-step @click.native="goOrderFour" title="上传口扫照片" description=""></el-step>
				  <el-step @click.native="goOrderFive" title="上传口内照片" description=""></el-step>
				  <el-step @click.native="goOrderSix" title="上传面向照片" description=""></el-step>
				  <el-step @click.native="goOrderSeven" title="收货地址" description=""></el-step>
				   <el-step @click.native="goOrderEight" title="确认订单" description=""></el-step>
				  <el-step @click.native="goOrderNine" title="订单生成" description=""></el-step>
				</el-steps>
			</div>
			<router-view></router-view>
			<!-- <el-footer>
				Copyright © www.techlion,.com.cn All Rights Reserved.<br>
				山东泰克莱恩 8008888888
			</el-footer> -->
		</div>
		
	</div>
</template>

<script>
	import MyHeader from '../components/MyHeader.vue'
	import Nav from '../components/Nav.vue'
	export default{
		data:function(){
			return{
				userName:''
			}
		},
		methods:{
		goOrderOne(){
			this.$router.push("/addorder/orderone");
		},
		goOrderTwo(){
			this.$router.push("/addorder/ordertwo");
		},
		goOrderThree(){
			this.$router.push("/addorder/orderthree");
		},
		goOrderFour(){
			this.$router.push("/addorder/orderfour");
		},
		goOrderFive(){
			this.$router.push("/addorder/orderfive");
		},
		goOrderSix(){
			this.$router.push("/addorder/ordersix");
		},
		goOrderSeven(){
			this.$router.push("/addorder/orderseven");
		},
		goOrderEight(){
			this.$router.push("/addorder/ordereight");
		},
		goOrderNine(){
			//this.$router.push("/addorder/ordernine");
		},
		},
		components:{
			MyHeader,
			Nav
		},
		mounted:function(){
			let loc=sessionStorage.getItem("location");
			if(loc==null||loc==undefined||loc==""){
				this.$router.push("/addOrder/orderone");
			}else{
				this.$router.push("/addOrder/"+loc)
			}
			
			let dname=JSON.parse(sessionStorage.getItem("loginInfo")).dname;
			this.userName=dname;
		}
		
	}
</script>

<style scoped="scoped">
	.addorder{
		height: 100%;
		width: 100%;
	}
	.con{
		height: 100%;
		/* overflow-y: auto;
		border:1px solid red; */
		background-image: linear-gradient(to bottom,white,lightgray);
		width: 80%;
		border-radius: 0.9375rem;
		margin: 0px auto;
	}
	.stepcon{
		padding-top: 0.625rem;
		height: 15%;
		width: 80%;
		margin: 0px auto;
		margin-top: 1.25rem;
	}
</style>
